import React from "react";
import { Helmet } from "react-helmet";
import styles from "./additional-pages.css";

/* eslint-disable @calm/react-intl/missing-formatted-message */
export const Exit = () => {
  return (
    <>
      <Helmet>
        <title>退出 | TouchWorld</title>
        <meta
          name="description"
          content="TouchWorldをご利用いただきありがとうございます。再度入室する際はブラウザの戻るボタンをクリックするか、ルームリンクから再度アクセスしてください。"
        />
      </Helmet>
      <div className={styles.body}>
        <div id="support-root"></div>
        <div id="exit-root" className={styles.exitRoot}>
          <div className={styles.exit_comment}>
            <p>
              TouchWorldをご利用いただきありがとうございます。
              <br />
              ブラウザのタブを閉じて終了してください。
              <br />
              <br />
              再度入室する際はブラウザの戻るボタンをクリックするか、ルームリンクから再度アクセスしてください。
            </p>
          </div>
        </div>
        <footer>
          <div className={styles.footer_lang}>
            <span>©株式会社リプロネクスト</span>
          </div>
        </footer>
      </div>
    </>
  );
};
