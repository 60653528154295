import React from "react";
import { createRoot } from "react-dom/client";
import { WrappedIntlProvider } from "./react-components/wrapped-intl-provider";
import registerTelemetry from "./telemetry";
import "./utils/theme";
import { HomePage } from "./react-components/home/HomePage";
import { AuthContextProvider } from "./react-components/auth/AuthContext";
import "./react-components/styles/global.scss";
import { ThemeProvider } from "./react-components/styles/theme";
import { store } from "./utils/store-instance";
import { Terms } from "./react-components/additional-pages/Terms";
import { Faq } from "./react-components/additional-pages/Faq";
import { Exit } from "./react-components/additional-pages/Exit";
import { PrivacyPolicy } from "./react-components/additional-pages/PrivacyPolicy";
import { CookieConsentBanar } from "./react-components/cookie/CookieConsentBanar";

registerTelemetry("/home", "Hubs Home Page");

window.APP = { store };

function HomeRoot() {
  // const location = useLocation();
  const qs = new URLSearchParams(window.location.search);
  const page = qs.get("page");
  return (
    <WrappedIntlProvider>
      <ThemeProvider store={store}>
        <AuthContextProvider store={store}>
          {page === "terms" ? (
            <Terms />
          ) : page === "faq" ? (
            <Faq />
          ) : page === "privacy-policy" ? (
            <PrivacyPolicy />
          ) : page === "exit" ? (
            <Exit />
          ) : (
            <HomePage />
          )}
        </AuthContextProvider>
        <CookieConsentBanar />
      </ThemeProvider>
    </WrappedIntlProvider>
  );
}

const container = document.getElementById("home-root");
const root = createRoot(container);
root.render(<HomeRoot />);
