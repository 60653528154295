import React from "react";
import CookieConsent from "react-cookie-consent";
import { useIntl } from "react-intl";

/* eslint-disable @calm/react-intl/missing-formatted-message */
export const CookieConsentBanar = () => {
  const intl = useIntl();
  return (
    <CookieConsent
      location="bottom"
      buttonText={intl.formatMessage({ id: "cookie-consent.accept", defaultMessage: "Accept" })}
      cookieName="ga-consent"
      style={{ minHeight: "96px", alignItems: "center", fontSize: "14px", pointerEvents: "auto" }}
      buttonStyle={{
        fontSize: "12px",
        padding: "12px 15px",
        color: "white",
        backgroundColor: "#2f70b6",
        textAlign: "center"
      }}
      expires={180}
      onAccept={() => {
        // GA有効化後に実装
      }}
    >
      <p>
        {intl.formatMessage({
          id: "cookie-consent.message",
          defaultMessage: "This website uses cookies to ensure you get the best experience on our website. "
        })}
        <a href="/?page=privacy-policy" target="_blank" style={{ textDecoration: "underline" }}>
          {intl.formatMessage({ id: "cookie-consent.privacy-policy", defaultMessage: "Privacy Policy" })}
        </a>
      </p>
    </CookieConsent>
  );
};
